<template>
  <div>
    <h1>Graph Explorer</h1>

    <label>Target</label>
    <select name="primary" v-model="primaryNode">
      <option disabled value="">Please select one</option>
      <option v-for="node in sortedNodes" :value="node" :key="node.id">{{node.description}}</option>
    </select>
    <p>Primary ID: {{primaryNode.description}} - {{primaryNode.id}}</p>
    <ul>
      <li v-for="link in primaryLinks" :key="link.id">{{nodesById[link.source].description}} --><a :href="link.href">{{link.note}}</a> --> {{nodesById[link.target].description}}</li>
    </ul>
    <br/>
    <label>Secondary Target</label>
    <select name="primary" v-model="secondaryNode">
      <option disabled value="">Please select one</option>
      <option v-for="node in sortedNodes" :value="node" :key="node.id">{{node.description}}</option>
    </select>
    <p>Secondary ID: {{secondaryNode.description}} - {{secondaryNode.id}}</p>
    <ul>
      <li v-for="link in secondaryLinks" :key="link.id">{{nodesById[link.source].description}} --><a :href="link.href">{{link.note}}</a> --> {{nodesById[link.target].description}}</li>
    </ul>




    <h2>Shared Connections</h2>
    <ul>
      <li v-for="link in sharedLinks" :key="link.id">{{nodesById[link.source].description}} --><a :href="link.href">{{link.note}}</a> --> {{nodesById[link.target].description}}</li>
    </ul>


  </div>

</template>

<script>

const netaverse = require('../assets/netaverse.json')
const nodesById = netaverse.nodes.reduce(function(nbi, node) {
  nbi[node.id] = node
  return nbi
}, {})
export default {
  name: 'GraphExplorer',
  data: function() {
    return {
      primaryNode:{},
      secondaryNode:{},
      netaverse,
      nodesById
    }
  },
  computed: {
    // a computed getter
    sharedLinks: function () {
      // `this` points to the vm instance
      const selectedNodes = [this.primaryNode.id, this.secondaryNode.id]
      return netaverse.links.filter(link => selectedNodes.includes(link.source) && selectedNodes.includes(link.target))
    },
    primaryLinks: function () {
      // `this` points to the vm instance
      return netaverse.links.filter(link => link.source === this.primaryNode.id || link.target === this.primaryNode.id)
    },
    secondaryLinks: function () {
      // `this` points to the vm instance
      return netaverse.links.filter(link => link.source === this.secondaryNode.id || link.target === this.secondaryNode.id)
    },
    sortedNodes: function(){
      return netaverse.nodes.sort((a,b) => a.description > b.description)
    },

  },
  props: {
    msg: String
  }
}
</script>